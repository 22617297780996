import React, { useState, useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { Button, Input, Select, Slider } from "@material-ui/core";
import { useConfirm } from "material-ui-confirm";
import { useDebounce } from "use-debounce";
import MenuItem from "@mui/material/MenuItem";

import { useFirebaseContext } from "../../../providers/firebase";
import {
  ISubscription,
  IEnterpriseSubscription,
  IUser,
} from "../../../../../types";
import { DailyUsageChart } from "./DailyUsageChart";
import { useStripeSubscription } from "../hooks/useStripeSubscription";
import { getIncludedApiUnits, getAccessLevel } from "../magic";

const capitaliseFirstLetterOfString = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const EnterpriseUsage = ({
  enterpriseSubscription,
  user,
}: {
  enterpriseSubscription: IEnterpriseSubscription;
  user: IUser;
}) => {
  const { deleteEnterpriseSubscription, updateEnterpriseSubscription, state } =
    useFirebaseContext();
  const confirm = useConfirm();
  const [temporaryAccessLevel, setTemporaryAccessLevel] = useState(
    enterpriseSubscription.accessLevel
  );
  const [temporaryWxtilesAccessLevel, setTemporaryWxtilesAccessLevel] =
    useState(enterpriseSubscription.wxtilesAccessLevel);
  const [temporaryNote, setTemporaryNote] = useState(
    enterpriseSubscription.note
  );
  const [temporaryLimit, setTemporaryLimit] = useState(
    enterpriseSubscription.limit
  );

  const [debouncedAccessLevel] = useDebounce(temporaryAccessLevel, 1000);
  const [debouncedWxtilesAccessLevel] = useDebounce(
    temporaryWxtilesAccessLevel,
    1000
  );
  const [debouncedNote] = useDebounce(temporaryNote, 1000);
  const [debouncedLimit] = useDebounce(temporaryLimit, 1000);

  useEffect(() => {
    if (
      enterpriseSubscription.accessLevel === temporaryAccessLevel &&
      enterpriseSubscription.note === temporaryNote &&
      enterpriseSubscription.limit === temporaryLimit &&
      enterpriseSubscription.wxtilesAccessLevel === temporaryWxtilesAccessLevel
    ) {
      return;
    }
    updateEnterpriseSubscription({
      ...enterpriseSubscription,
      wxtilesAccessLevel: debouncedWxtilesAccessLevel,
      accessLevel: debouncedAccessLevel,
      note: debouncedNote,
      limit: debouncedLimit,
    });
  }, [
    debouncedAccessLevel,
    debouncedNote,
    debouncedLimit,
    debouncedWxtilesAccessLevel,
  ]);

  if (state.isLoadingUsage) return <Loading />;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        borderBottomLeftRadius: 4,
        borderBottomRightRadius: 4,
        justifyContent: "center",
        padding: 24,
        textAlign: "center",
        position: "relative",
        gap: 8,
      }}
    >
      <div
        style={{
          flex: 1,
          textAlign: "center",
          fontWeight: "bold",
          paddingBottom: 16,
        }}
      >
        Enterprise
      </div>
      <div
        style={{
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          gap: 16,
        }}
      >
        <Tag text="Included Units" style={{ backgroundColor: "#7599ff" }} />
        <Tag
          text="Projected"
          style={{
            backgroundColor: "#ececec",
            color: "#182445",
            border: "1px dashed #ccc",
          }}
        />
      </div>
      <div style={{ flex: 1 }}>{state.usageItems && <DailyUsageChart />}</div>
      {user.isAdmin && (
        <div
          style={{
            display: "flex",
            backgroundColor: "#e5e9f6",
            flexDirection: "column",
            padding: 32,
            gap: 32,
            position: "absolute",
            top: 0,
            right: -310,
            borderRadius: 4,
          }}
        >
          <div style={{ flex: 1, fontWeight: "bold" }}>Admin section</div>
          <div style={{ flex: 1, paddingLeft: 12, paddingRight: 12 }}>
            <div>Access level</div>
            <Slider
              value={temporaryAccessLevel}
              max={Math.max(...state.plans.map(getAccessLevel))}
              min={Math.min(...state.plans.map(getAccessLevel))}
              step={null}
              marks={state.plans
                .sort((a, b) => {
                  return getAccessLevel(a) > getAccessLevel(b) ? 1 : -1;
                })
                .map((plan) => {
                  return {
                    value: getAccessLevel(plan),
                    label: plan.stripe_metadata_title,
                  };
                })}
              onChange={(e, value) => {
                setTemporaryAccessLevel(value as number);
              }}
            />
          </div>
          {state.enterpriseSubscription && (
            <div style={{ flex: 1 }}>
              <div>Limit</div>
              <div>
                <Select
                  value={state.enterpriseSubscription.limit}
                  label={"limit"}
                  labelId="demo-simple-select-label"
                  style={{ textAlign: "center" }}
                  onChange={(e) => {
                    const usingAccount = state.user?.usingAccount;
                    if (!usingAccount) return;

                    setTemporaryLimit(e.target.value as number);
                  }}
              >
                  <MenuItem
                    key={"limit"}
                    value={state.enterpriseSubscription.limit}
                  >
                    {state.enterpriseSubscription.limit.toLocaleString()}
                  </MenuItem>
                  {state.enterpriseSubscription.apiUnitsUsed >
                    state.enterpriseSubscription.limit && (
                    <MenuItem
                      key={"apiUnitsUsed"}
                      value={state.enterpriseSubscription.apiUnitsUsed}
                    >
                      {state.enterpriseSubscription.apiUnitsUsed.toLocaleString()}
                    </MenuItem>
                    )}
                  {[
                    ...Array.from({ length: 11 }).map(
                      (_, i) => i * 1000000 * 2
                    ),
                    ...state.plans.map((plan) => {
                      const value = getIncludedApiUnits(plan) ?? 0;
                      return value;
                    }),
                  ]
                    .sort((a, b) => (a > b ? 1 : -1))
                    .filter((limitee) => {
                      if (!state.enterpriseSubscription) return false;
                      return (
                        limitee > state.enterpriseSubscription.apiUnitsUsed
                      );
                    })
                    .map((limitee) => {
                      return (
                        <MenuItem key={limitee} value={limitee}>
                          {limitee.toLocaleString()}
                        </MenuItem>
                      );
                    })}
                </Select>
              </div>
            </div>
          )}
          <div style={{ flex: 1 }}>
            <div>Note</div>
            <div>
              <Input
                value={temporaryNote}
                onChange={(e) => {
                  setTemporaryNote(e.target.value);
                }}
              />
            </div>
          </div>
          <div style={{ flex: 1, paddingLeft: 12, paddingRight: 12 }}>
            <div>WXTiles Access level</div>
            <Slider
              value={temporaryWxtilesAccessLevel}
              step={null}
              marks={[0, 10, 20, 30, 40, 50].map((value) => {
                return {
                  value,
                  label: value,
                };
              })}
              max={50}
              min={0}
              onChange={(e, value) => {
                setTemporaryWxtilesAccessLevel(value as number);
              }}
            />
          </div>
          <div style={{ flex: 1 }}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                confirm({
                  title: "",
                  description:
                    "Are you sure you want to cancel this enterprise subscription?",
                  confirmationText: "Yes, cancel this enterprise subscription",
                  confirmationButtonProps: {
                    color: "secondary",
                  },
                }).then(deleteEnterpriseSubscription);
              }}
            >
              Cancel enterprise subscription
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

const NoSubscription = () => {
  const { state, createEnterpriseSubscription } = useFirebaseContext();
  const confirm = useConfirm();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: 360,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          textAlign: "center",
          fontWeight: "bold",
          fontSize: 20,
          paddingBottom: 16,
        }}
      >
        <div>Subscribe to a plan to activate your API keys</div>
      </div>
      {state.user?.isAdmin && (
        <div
          style={{
            backgroundColor: "#e5e9f6",
            borderRadius: 4,
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            padding: 8,
            gap: 8,
            position: "absolute",
            top: 8,
            right: 8,
          }}
        >
          <div style={{ fontWeight: "bold" }}>Admin section</div>
          <div
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  confirm({
                    dialogProps: {
                      open: true,
                      maxWidth: "xs",
                    },
                    title: null,
                    description: (
                      <div style={{ textAlign: "center" }}>
                        Are you sure you want to create an enterprise
                        subscription?
                      </div>
                    ),
                    confirmationText: "Yes, make this subscription enterprise",
                    cancellationText: "Cancel",
                    confirmationButtonProps: {
                      color: "primary",
                    },
                  })
                    .then(createEnterpriseSubscription)
                    .catch(() => console.log("dont delete"));
                }}
              >
                Create an enterprise subscription
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const Loading = () => {
  return (
    <div
      style={{
        flex: 1,
        textAlign: "center",
        paddingTop: 24,
        minHeight: 360,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress />
    </div>
  );
};

const SubscriptionView = ({
  forecastSubscription,
}: {
  forecastSubscription: ISubscription;
}) => {
  const { state, setLimit } = useFirebaseContext();
  const { subscription: stripeSubscription } = useStripeSubscription(
    state,
    state.user?.usingAccount
  );

  if (!stripeSubscription || state.isLoadingUsage) return <Loading />;

  const limit = state.subscription?.limit;

  const plan = state.plans.find((p) => p.id === forecastSubscription.productId);

  const lowerLimit =
    plan?.prices
      .filter((p) => p.active)
      .find((p) => p.billing_scheme === "tiered")
      ?.tiers?.find((t) => typeof t.up_to === "number")?.up_to ?? 0;

  const apiUnitsUsed = state.usageItems.reduce((sum, usageItem) => {
    return (sum += usageItem.charge);
  }, 0);

  return (
    <div
      style={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        padding: 48,
        paddingTop: 24,
        gap: 16,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          borderBottom: "1px solid #cecece",
          paddingBottom: 16,
        }}
      >
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div style={{ flex: 1 }}>
            <div style={{ fontWeight: "bold" }}>API Units</div>
            <div style={{ fontWeight: 550 }}>
              {apiUnitsUsed.toLocaleString()} units used (
              {Math.floor((apiUnitsUsed / (limit ?? 1)) * 100)}%)
            </div>
          </div>
          <div style={{ flex: 1, textAlign: "center", fontWeight: "bold" }}>
            {capitaliseFirstLetterOfString(plan?.stripe_metadata_tier ?? "")}
          </div>
          <div style={{ flex: 1, textAlign: "right" }}>
            <Select
              value={limit}
              label={"limit"}
              labelId="demo-simple-select-label"
              style={{ textAlign: "center" }}
              disabled={plan?.metadata.tier === "starter"}
              onChange={(e) => {
                const usingAccount = state.user?.usingAccount;
                if (!usingAccount) return;
                setLimit(e.target.value as number, usingAccount);
              }}
            >
              {apiUnitsUsed > lowerLimit && (
                <MenuItem key={"apiUnitsUsed"} value={apiUnitsUsed}>
                  {apiUnitsUsed.toLocaleString()}
                </MenuItem>
              )}
              {Array.from({ length: 10 })
                .map((_, i) => i * lowerLimit)
                .filter((limitee) => {
                  return limitee > apiUnitsUsed;
                })
                .map((limitee) => {
                  return (
                    <MenuItem key={limitee} value={limitee}>
                      {limitee.toLocaleString()}
                    </MenuItem>
                  );
                })}
            </Select>
            <div style={{ fontSize: "0.6em" }}>Set custom limit</div>
          </div>
        </div>
      </div>
      <div
        style={{
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          gap: 16,
        }}
      >
        <Tag text="Included Units" style={{ backgroundColor: "#7599ff" }} />
        <Tag
          text="Up to 80% units used"
          style={{ backgroundColor: "#74a310" }}
        />
        <Tag
          text="Over 80% units used"
          style={{ backgroundColor: "#df7b00" }}
        />
        <Tag
          text="Over 90% units used"
          style={{ backgroundColor: "#fa6161" }}
        />
        <Tag
          text="Projected"
          style={{
            backgroundColor: "#ececec",
            color: "#182445",
            border: "1px dashed #ccc",
          }}
        />
      </div>
      <div style={{ flex: 1 }}>{state.usageItems && <DailyUsageChart />}</div>
    </div>
  );
};

interface TagProps {
  text: string;
  style?: React.CSSProperties;
}
const Tag = ({ text, style = {} }: TagProps) => {
  return (
    <div
      style={{
        borderRadius: 4,
        backgroundColor: "#ececec",
        paddingLeft: 8,
        paddingRight: 8,
        paddingTop: 4,
        paddingBottom: 4,
        fontSize: "0.8em",
        color: "white",
        fontWeight: 600,
        display: "inline-block",
        ...style,
      }}
    >
      {text}
    </div>
  );
};

export const UsageTab = () => {
  const { state } = useFirebaseContext();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        borderBottomLeftRadius: 4,
        borderBottomRightRadius: 4,
        justifyContent: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          borderBottomLeftRadius: 4,
          borderBottomRightRadius: 4,
          width: "100%",
          position: "relative",
        }}
      >
        {!state.user && <div />}
        {state.user && state.enterpriseSubscription && (
          <EnterpriseUsage
            key={state.enterpriseSubscription.id}
            {...{
              enterpriseSubscription: state.enterpriseSubscription,
              user: state.user,
            }}
          />
        )}
        {state.user && state.subscription && (
          <SubscriptionView {...{ forecastSubscription: state.subscription }} />
        )}
        {state.user && !state.subscription && !state.enterpriseSubscription && (
          <NoSubscription />
        )}
      </div>
    </div>
  );
};
